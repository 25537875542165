:root {
  --primary-color: #ea2845;
  --primary-accent-color: #ea2868;
  --primary-gradient: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-accent-color) 100%);
}

html {
  min-height: 450px;
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Montserrat, sans-serif;
  font-size: 17.5px;
  font-weight: 400;
}

* {
  outline: 0 !important;
}

a:hover, a:focus, a:active {
  text-decoration: none;
}

a:focus, a:active {
  color: #fff;
}

.gradient-svg {
  position: absolute;
}

.logo-wrapper svg, .logo-wrapper img {
  max-width: 70px;
}

.nav-wrapper > li > a, .nav-wrapper > li > span {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  transition: all .1s ease-in;
}

.nav-wrapper > li:not(.social-icon) {
  margin-left: 25px;
}

.nav-wrapper .arrow {
  margin-left: 4px;
  font-size: 12px;
}

.nav-wrapper > li > span:hover > .arrow {
  color: #ea2845;
  cursor: pointer;
}

.nav-wrapper > li > a:hover, .nav-wrapper > li > span:hover, .sub-nav-wrapper li a:hover {
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  -webkit-background-clip: text;
}

.nav-wrapper > li > span:hover > .new {
  -webkit-text-fill-color: #fff;
}

.nav-wrapper > li > span > * {
  vertical-align: middle;
  display: inline-block;
}

.nav-wrapper .text {
  margin-left: 4px;
}

.nav-wrapper a:hover span:not(.new), .nav-wrapper .sub-nav-trigger:hover {
  color: #ea2845;
  cursor: pointer;
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.nav-wrapper a:hover svg path {
  fill: url("#primary-gradient");
}

.nav-wrapper > li {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.nav-wrapper .icon {
  margin-left: 27px;
  font-size: 22px;
}

.nav-wrapper > li:hover > .sub-nav-outlet {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.sub-nav-outlet {
  visibility: hidden;
  opacity: 0;
  padding-top: 6px;
  transition: all .1s ease-in;
  position: absolute;
  left: 6px;
  right: -25px;
  transform: translateY(-10px);
}

.sub-nav-wrapper {
  text-align: left;
  background: #0f1114;
  border-radius: 8px;
  padding: 24px;
}

.navbar-sticky .sub-nav-wrapper {
  background: #fff;
  box-shadow: 0 2px 6px #00000012;
}

.sub-nav-wrapper li {
  line-height: 14px;
  list-style: none;
}

.sub-nav-wrapper li a {
  color: #fff;
  text-transform: uppercase;
  padding: 5px 0;
  font-size: 13px;
  font-weight: 700;
  transition: all .1s ease-in;
  display: inline-block;
}

.navbar-sticky .sub-nav-wrapper li a {
  color: #090909;
}

.sub-nav-wrapper ul {
  margin: 0 0 16px;
  padding: 0;
}

.sub-nav-wrapper ul:last-of-type {
  margin-bottom: 0;
}

.sub-nav-wrapper .nav-category {
  color: #ffffff80;
  text-transform: uppercase;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: 600;
  display: inline-flex;
}

.navbar-sticky .sub-nav-wrapper .nav-category {
  color: #00000080;
  font-weight: 700;
}

.mobile-nav-icon {
  margin: 30px 0;
  display: none;
  position: relative;
}

@media (width <= 1020px) {
  .mobile-nav-icon {
    display: block;
  }
}

.mobile-nav-icon span {
  background: #fff;
  width: 24px;
  height: 2px;
  transition: all .1s ease-in;
  display: block;
}

.mobile-nav-icon span:before, .mobile-nav-icon span:after {
  content: "";
  background: #fff;
  width: 24px;
  height: 2px;
  transition: all .1s ease-in;
  position: absolute;
}

.mobile-nav-icon span:before {
  top: -8px;
}

.mobile-nav-icon span:after {
  bottom: -8px;
}

.mobile-nav-icon:hover {
  cursor: pointer;
}

.mobile-nav-icon:hover span, .mobile-nav-icon:hover span:before, .mobile-nav-icon:hover span:after {
  background: var(--primary-gradient);
}

.mobile-nav-icon .fill {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  position: absolute;
}

.mobile-nav {
  z-index: 1000000;
  background: #111;
  flex-direction: column;
  padding: 24px 15px;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

@media (width <= 1020px) {
  .mobile-nav.open {
    display: flex;
  }
}

body.mobile-nav-open {
  overflow: hidden;
}

.mobile-nav-logo img {
  width: 70px;
}

.mobile-nav-head {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mobile-nav-close span {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
}

.mobile-nav-close span:before, .mobile-nav-close span:after {
  content: "";
  background: #fff;
  width: 24px;
  height: 2px;
  transition: all .1s ease-in;
  position: absolute;
}

.mobile-nav-close span:hover:before, .mobile-nav-close span:hover:after {
  background: var(--primary-gradient);
}

.mobile-nav-close span:before {
  top: 10px;
  transform: rotate(45deg);
}

.mobile-nav-close span:after {
  top: 10px;
  transform: rotate(-45deg);
}

.mobile-nav ul {
  margin: 32px 0;
  padding: 0;
}

.mobile-nav li {
  line-height: 36px;
  list-style: none;
}

.mobile-nav li a {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.mobile-nav li a span {
  vertical-align: middle;
}

.mobile-nav .new {
  margin-left: 6px;
  margin-right: 0;
}

.mobile-nav li a:hover span:not(.new) {
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.mobile-nav .btn-primary {
  width: 100%;
  min-width: 100%;
  padding: 14px;
  font-size: 15px;
  font-weight: 600;
}

.mobile-nav-footer {
  margin-top: auto;
}

.d-inline-block {
  display: inline-block;
}

.top-wrapper {
  padding-top: 30px;
}

@media (width <= 1020px) {
  .top-wrapper {
    padding-top: 24px;
  }
}

.m-l-30 {
  margin-left: 30px !important;
}

.page-header {
  color: #fff;
  background-color: #090909;
  background-image: url("header.e5c9eff6.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 760px;
}

.main-wrapper {
  padding-top: 140px;
}

.main-wrapper h1 {
  margin: 0 0 5px;
  font-size: 55px;
  font-weight: 600;
  line-height: 75px;
}

.main-wrapper h4 {
  max-width: 600px;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.buttons-wrapper {
  padding: 60px 0;
}

.buttons-wrapper .btn:first-child {
  margin-right: 20px;
}

.buttons-wrapper * {
  vertical-align: middle;
}

.buttons-wrapper .btn .fa-github {
  vertical-align: middle;
  margin-right: 6px;
  font-size: 18px;
}

.btn {
  color: #fff;
  text-align: center;
  border-radius: 30px;
  min-width: 230px;
  padding: 16px 28px;
  font-size: 16px;
  font-weight: 500;
}

.btn-primary {
  background: var(--primary-gradient);
  color: #fff;
  position: relative;
  overflow: hidden;
}

.btn-primary:before {
  content: "";
  background: var(--primary-gradient);
  -webkit-mask-composite: xor;
  pointer-events: none;
  z-index: 2;
  border-radius: 30px;
  padding: 2px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.btn-primary span {
  z-index: 3;
  position: relative;
}

.btn-primary:hover {
  background: none;
}

.btn-primary:hover span, .btn-primary:active span, .btn-primary:focus span {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.btn-secondary {
  color: #fff;
  position: relative;
}

.btn-secondary:before {
  content: "";
  -webkit-mask-composite: xor;
  pointer-events: none;
  z-index: 2;
  background: #fff;
  border-radius: 30px;
  padding: 2px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.btn-secondary:hover {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.btn-secondary:hover:before {
  background: var(--primary-gradient);
}

.btn img {
  margin-right: 5px;
}

.btn span {
  vertical-align: middle;
}

.event-bar {
  color: #fff;
  text-align: center;
  background-color: #eb2845;
  background-image: url("mane-bg.72ca700a.png");
  background-position: 70% 0;
  background-repeat: no-repeat;
  padding: 6px 10px 4px;
  font-size: 18px;
  font-weight: 500;
  transition: all .1s ease-in;
  display: block;
}

.event-bar:hover {
  color: #fff;
  background-color: #292526;
}

.event-bar p {
  vertical-align: middle;
  margin: 0;
  line-height: 32px;
  display: inline-block;
}

.event-bar p strong {
  font-weight: 600;
}

.event-bar img {
  height: 60px;
  margin-bottom: 12px;
}

.highlights-wrapper, .discover-wrapper {
  text-align: center;
  background: linear-gradient(360deg, #f1f1f1, #f9f9f9);
  padding: 100px 0;
}

.blocks-row {
  margin-top: 80px;
}

.blocks-wrapper {
  text-align: center;
  background: #fafafa;
  padding: 100px 0;
}

.block {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
  height: 300px;
  margin-bottom: 30px;
  padding: 35px 20px;
}

@media screen and (width <= 768px) {
  .block {
    height: auto;
    margin: 0 20px 30px;
  }

  .blocks-row > div:last-of-type > .block {
    margin-bottom: 0;
  }
}

.block h5 {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
}

.block p {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

.discover-wrapper {
  background: #ebebeb;
}

.discover-wrapper .heading h4, .highlights-wrapper .heading h4, .blocks-wrapper .heading h4 {
  margin: 0;
  font-size: 36px;
  line-height: 60px;
}

.discover-wrapper .heading p, .highlights-wrapper .heading p, .blocks-wrapper .heading p {
  color: #000;
  max-width: 700px;
  margin: 15px auto 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.discover-wrapper .heading a {
  color: #ea2845;
}

.highlights-wrapper .icon-wrapper {
  width: 70px;
  height: 75px;
  margin: 0 auto;
  display: block;
}

.blocks-wrapper .icon-wrapper {
  width: 50px;
  height: 55px;
  margin: 0 auto;
  display: block;
}

.highlights-wrapper h5, .discover-wrapper h5 {
  text-transform: uppercase;
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin: 40px 0 0;
  font-size: 18px;
  font-weight: bold;
}

.highlights-wrapper p, .discover-wrapper p {
  max-width: 300px;
  margin: 20px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.heading a {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.heading a:hover {
  color: #111;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: none;
}

.sponsors-wrapper, .video-wrapper {
  text-align: center;
  padding: 100px 0;
}

.video-wrapper {
  background: #0a0a0a;
}

.sponsors-wrapper {
  background: #fdfdfd;
}

.sponsors-wrapper h4, .video-wrapper h4 {
  margin: 0;
  font-size: 36px;
  line-height: 60px;
}

.video-wrapper h4 {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.sponsors-wrapper h5, .video-wrapper h5 {
  letter-spacing: .3px;
  text-transform: uppercase;
  font-size: 17px;
}

.sponsors-wrapper p, .video-wrapper p {
  color: #000;
  max-width: 700px;
  margin: 15px auto 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.video-wrapper h4, .video-wrapper p {
  color: #fff;
}

.video-wrapper p a {
  color: #ed1543;
  font-weight: 600;
}

.support-section {
  max-width: 1200px;
  margin-bottom: 47px;
  margin-left: auto;
  margin-right: auto;
}

.support-section a {
  vertical-align: top;
  background-color: #f6f6f6;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 140px;
  margin-bottom: 5px;
  padding: 25px;
  display: inline-flex;
}

.support-section a:nth-child(2n) {
  background-color: #0000;
}

.support-section a img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.support-section--gold a {
  width: 140px;
  height: 90px;
}

.support-section--silver a {
  width: 150px;
  max-height: 95px;
}

.support-section--bronze a {
  width: 92px;
  height: 60px;
  padding: 12px;
}

.support-section:first-of-type {
  margin-top: 50px;
}

.support-section img {
  filter: grayscale();
  opacity: .5;
}

.support-section img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.btn-black {
  color: #fff;
  background: #090909;
  border: 2px solid #090909;
}

.btn-black span {
  color: #969696;
  margin: 0 3px;
  transition: all .1s ease-in;
}

.btn-black:hover {
  color: #fff;
  background: #ea2845;
  border: 2px solid #ea2845;
}

.btn-black:hover span {
  color: #fff;
}

.page-footer {
  text-align: center;
  color: #090909;
  background-color: #f1f3f3;
  padding: 60px 0;
}

.page-footer .icon {
  color: #c2c3c3;
  margin: 0 10px;
  font-size: 24px;
}

.page-footer .icon:hover {
  color: #090909;
}

.page-footer p {
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}

.page-footer p a {
  color: var(--primary-color);
  background: var(--primary-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 600;
}

.page-footer p a:hover {
  -webkit-text-fill-color: transparent;
  background: #111;
  -webkit-background-clip: text;
}

.navbar-sticky {
  z-index: 100000;
  opacity: 0;
  background: #fff;
  height: 60px;
  padding: 8px 0;
  transition: all .18s ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  transform: translate3d(0, -100%, 0);
  box-shadow: 0 2px 6px #00000012;
}

.navbar-sticky.visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.navbar-sticky .logo-wrapper img {
  width: 40px;
}

.navbar-sticky .nav-wrapper {
  text-align: right;
  vertical-align: middle;
  width: calc(100% - 50px);
  margin: 5px 0 0;
}

.navbar-sticky .nav-wrapper > li > a, .navbar-sticky .nav-wrapper > li > span {
  color: #090909;
  font-size: 14px;
}

.navbar-sticky .nav-wrapper a:hover {
  color: #ea2845;
}

.navbar-sticky .nav-wrapper .icon {
  margin-left: 12px;
  font-size: 16px;
}

.enterprise-wrapper {
  background-color: #ed1543;
  background-image: url("support.1356a495.png");
  background-position: 0 100%;
  background-repeat: no-repeat;
  height: 500px;
  overflow: hidden;
}

.enterprise-column {
  float: right;
  color: #fff;
  width: 60%;
  padding: 90px 0;
}

.enterprise-column h2 {
  margin-top: 0;
  font-size: 32px;
}

.enterprise-column p {
  font-weight: 500;
  line-height: 32px;
}

.enterprise-column .btn {
  color: #ed1543;
  background: #fff;
  border-color: #fff;
  min-width: 200px;
  margin-top: 25px;
  font-weight: 600;
  -webkit-text-fill-color: unset !important;
  -webkit-background-clip: unset !important;
}

.enterprise-column .btn:hover {
  color: #fff;
  background: none;
}

.enterprise-column .btn:hover:before {
  background: #fff;
}

.newsletter-wrapper {
  background: #ebebeb;
  padding: 45px 0 35px;
  position: relative;
  overflow: hidden;
}

.newsletter-wrapper h3 {
  color: #111;
  margin: 0 0 4px;
  font-size: 17px;
  font-weight: 600;
}

.newsletter-wrapper p {
  color: #7b7b7b;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.newsletter-wrapper .hgroup, .newsletter-form {
  vertical-align: middle;
  display: inline-block;
}

.newsletter-form {
  text-align: right;
  width: 40%;
  margin-top: 5px;
  position: relative;
}

.newsletter-wrapper .hgroup {
  float: left;
  width: 60%;
  position: relative;
}

.newsletter-form .form-control {
  vertical-align: middle;
  background: #fdfdfd;
  border: 0;
  border-radius: 3px;
  width: calc(100% - 70px);
  max-width: 100%;
  height: 40px;
  padding: 10px 20px;
  font-size: 13px;
  box-shadow: -2px 2px 3px #dcdcdc;
}

.newsletter-form button {
  background: var(--primary-color);
  background: var(--primary-gradient);
  color: #fff;
  vertical-align: middle;
  border: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -45px;
  padding: 13px 18px;
  font-size: 14px;
  transition: none;
}

.newsletter-form button:hover, .newsletter-form button[disabled] {
  background: #111;
}

.newsletter-form button .icon-success, .newsletter-form .btn-success .fa-paper-plane {
  display: none;
}

.newsletter-form .btn-success[disabled] {
  color: #1dd81c;
  pointer-events: none;
  background: #d3e6d4;
}

.newsletter-form .btn-success .icon-success {
  display: block;
}

.newsletter-wrapper .envelope-bg {
  color: #dedede;
  z-index: 0;
  margin-top: -50px;
  margin-left: 60px;
  font-size: 180px;
  position: absolute;
  transform: rotate(-30deg);
}

.companies-tiles {
  margin: 70px auto;
}

.companies-tiles a {
  text-align: center;
  float: left;
  width: 20%;
  height: 60px;
  margin: 30px 0;
  position: relative;
}

.companies-tiles img {
  filter: grayscale();
  opacity: .5;
  max-width: 135px;
  max-height: 80px;
}

.companies-tiles img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.companies-tiles a img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%)translateX(-50%);
}

.other-services {
  padding: 100px 0;
}

.card {
  border-radius: 12px;
  padding: 60px;
  position: relative;
  overflow: hidden;
}

.card--dark {
  background: #0e0e10;
}

.card--primary {
  background: #ee1744;
}

.card--dark, .card--primary {
  color: #fff;
  min-height: 720px;
}

.card--light {
  background: #e7e7e7;
  min-height: 520px;
  margin-top: 30px;
}

.card--light .card-content {
  max-width: 400px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ellipse-overlay {
  z-index: 1;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 800px;
  height: 800px;
  position: absolute;
}

.card--primary .ellipse-overlay {
  opacity: .3;
  bottom: -30%;
  right: -75%;
}

.card--dark .ellipse-overlay {
  opacity: .1;
  top: -30%;
  left: -40%;
}

.card--light .ellipse-overlay {
  opacity: .1;
  border-color: #000;
  top: 40%;
  left: 40%;
}

.card-content {
  z-index: 5;
  position: relative;
}

.card-image {
  z-index: 5;
}

.card h2 {
  margin: 0;
  font-size: 32px;
  font-weight: 600;
}

.card p {
  margin: 8px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.card .btn-secondary {
  color: #000;
  background: #fff;
  border-color: #fff;
  min-width: auto;
  margin-top: 32px;
  padding: 14px 32px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  -webkit-text-fill-color: unset !important;
  -webkit-background-clip: unset !important;
}

.card--dark .btn-secondary:hover, .card--primary .btn-secondary:hover {
  background: none;
}

.card--light .btn-secondary:hover {
  color: #fff;
  background: #111;
}

.card--light .btn-secondary:hover:before {
  background: #111;
}

.card--dark .btn-secondary:hover, .card--primary .btn-secondary:hover {
  color: #fff;
}

.card--dark .btn-secondary:hover:before, .card--primary .btn-secondary:hover:before {
  background: #fff;
}

.card--light .btn-secondary {
  margin-top: 24px;
}

.card--dark .card-image, .card--primary .card-image {
  border-radius: 24px;
  width: 150%;
  position: absolute;
  bottom: -40px;
  left: 60px;
  right: 0;
  overflow: hidden;
}

.card--light .card-image {
  border-radius: 24px;
  width: 100%;
  position: absolute;
  top: 60px;
  right: -60%;
}

.card-image > img, .card-image .image-wrapper img {
  border-radius: 24px;
  max-width: 100%;
  position: static;
}

.card--dark .card-image {
  box-shadow: -20px -20px 60px 15px #e316431a;
}

.card--primary .card-image {
  box-shadow: -20px -20px 60px 15px #00000026;
}

.card--dark .card-image > img {
  border: 12px solid #1c1c1e;
}

.card--primary .card-image > img {
  border: 12px solid #ef254f;
}

.card--light .image-wrapper > img {
  border: 12px solid #474747;
}

.image-wrapper {
  z-index: 10;
  background: #111;
  border-radius: 24px;
  position: relative;
  box-shadow: -20px -20px 60px 15px #e3164326;
}

.card-boxes {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -70px;
}

.card-box {
  border-radius: 12px;
  position: absolute;
  overflow: hidden;
}

.card-box:first-of-type {
  z-index: 2;
  width: 125px;
  top: 15%;
}

.card-box:last-of-type {
  z-index: 3;
  width: 150px;
  top: 35%;
  left: -100px;
}

.card-box img {
  max-width: 100%;
}

@media (width >= 1400px) {
  .other-services .row {
    margin-left: -120px;
    margin-right: -120px;
  }
}

@media (width <= 1199px) and (width >= 992px) {
  .card--dark, .card--primary {
    min-height: 640px;
  }

  .card--light .card-image {
    right: -68%;
  }
}

@media (width <= 991px) {
  .other-services .row > div:nth-child(2) {
    margin-top: 30px;
  }

  .other-services .card {
    padding: 40px;
  }

  .card--dark .card-image, .card--primary .card-image {
    left: 40px;
  }

  .card--light {
    min-height: 750px;
  }

  .card--light .card-content {
    max-width: none;
    position: static;
    transform: none;
  }

  .card--light .card-image {
    width: 150%;
    inset: auto 0 -40px 40px;
  }
}

@media (width <= 640px) {
  .card h2 {
    font-size: 28px;
  }

  .card--dark, .card--primary {
    min-height: 640px;
  }

  .card--light {
    min-height: 580px;
  }

  .card--light .card-image {
    width: 700px;
    bottom: -120px;
  }
}

@media (width <= 480px) {
  .card--dark, .card--primary {
    min-height: 580px;
  }

  .card--light .card-image {
    bottom: -160px;
  }
}

@media (width <= 400px) {
  .card--dark, .card--primary {
    min-height: 500px;
  }
}

@media (width <= 1300px) {
  .enterprise-wrapper {
    background-position: -250px bottom;
  }

  .enterprise-column {
    width: 70%;
    padding: 70px;
  }

  .hidden-lg {
    display: none !important;
  }
}

@media (width <= 1020px) {
  .newsletter-wrapper .hgroup, .newsletter-form {
    width: 50%;
  }

  .hidden-md {
    display: none !important;
  }
}

@media (width <= 991px) {
  .enterprise-wrapper {
    background-position: -400px bottom;
  }

  .enterprise-column {
    text-align: center;
    width: 100%;
  }

  .main-wrapper {
    text-align: center;
  }

  .main-wrapper h4 {
    margin-left: auto;
    margin-right: auto;
  }

  .buttons-wrapper .btn:first-child, .buttons-wrapper .btn {
    margin: 0 10px;
  }
}

@media (width <= 920px) {
  .highlight-block {
    margin-bottom: 50px;
  }

  .highlight-block:last-of-type {
    margin-bottom: 0;
  }

  .hidden-sm {
    display: none !important;
  }

  .page-header {
    background-position: 35% bottom;
  }

  .navbar-sticky {
    display: none;
  }
}

@media (width <= 680px) {
  .enterprise-wrapper {
    background: #ed1543;
    height: auto;
  }

  .enterprise-column {
    padding-left: 0;
    padding-right: 0;
  }

  .newsletter-wrapper .hgroup, .newsletter-form {
    width: 100%;
  }

  .newsletter-wrapper .hgroup {
    float: none;
    text-align: center;
  }

  .newsletter-form {
    text-align: center;
    margin-top: 30px;
  }

  .newsletter-form .form-control {
    margin-left: 0;
  }

  .newsletter-wrapper .envelope-bg {
    font-size: 280px;
  }
}

@media (width <= 640px) {
  .hidden-xs {
    display: none !important;
  }

  .buttons-wrapper .btn, .buttons-wrapper .btn:first-child {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .main-wrapper {
    padding-top: 100px;
  }

  .company-logo {
    margin: 30px auto 0;
    display: block;
  }

  .buttons-wrapper .btn:first-of-type {
    margin-bottom: 20px;
  }

  .event-bar {
    padding: 12px 0 30px;
  }

  .event-bar p {
    display: block;
  }

  .plyr--video {
    margin: 0 25px;
  }

  .plyr {
    margin: 0;
  }
}

.plyr__video-wrapper:not(.hidden-poster):before {
  content: "";
  z-index: 100;
  background: url("video-poster.ed50b6ac.png") center / 100% no-repeat;
  width: 100%;
  position: absolute;
  inset: -2px 0 0;
}

.plyr__video-embed:not(.plyr__video-wrapper) {
  visibility: hidden;
}

.live-wrapper {
  text-align: center;
  color: #fff;
  background: #111;
  padding: 100px 0;
}

.live-wrapper h4 {
  margin: 0;
  font-size: 36px;
  line-height: 60px;
}

.live-wrapper p {
  max-width: 700px;
  margin: 15px auto 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.new {
  background: var(--primary-gradient);
  vertical-align: middle;
  border-radius: 2px;
  margin-right: 2px;
  padding: 2px 4px;
  font-size: 11px;
  color: #fff !important;
}

.plyr__poster {
  display: none !important;
}
/*# sourceMappingURL=index.a87e8b1c.css.map */
